<template>
  <div class="">
    <!-- <ClientOnly> -->
      <div class="flex items-center cursor-pointer" @click="onProfileSwitchClicked">
        <div v-if="isLoggedIn && isProducer" class="hidden md:block text-right">
          <h3 class="font-medium">{{ currentProducer?.name.split(" ")[0] }}</h3>
          <p class="-mt-1 text-xs">produtor (a)</p>
        </div>
        <div v-else-if="isLoggedIn" class="hidden md:block text-right">
          <h3 class="font-medium">{{ currentUser?.name.split(" ")[0] }}</h3>
          <p class="-mt-1 text-xs">pessoal</p>
        </div>
        <div v-else class="block text-right">
          <h3 class="font-medium">entre</h3>
          <p class="-mt-1 text-xs">na pixta</p>
        </div>

        <div class="icon" v-if="isLoggedIn">
          <icon-chevron-down />
        </div>
        <figure v-if="isLoggedIn && isProducer" class="w-10 h-10 rounded-full">
          <img v-if="currentProducer?.avatar_picture_url" :src="currentProducer?.avatar_picture_url" class="w-full h-auto" alt="">
          <span v-else class="flex w-full h-full items-center justify-center font-bold bg-black text-white dark:bg-white dark:text-black rounded-full">
            {{ currentProducer?.name[0] }}
          </span>
        </figure>
        <figure v-else-if="isLoggedIn" class="w-10 h-10 rounded-full">
          <img v-if="currentUser?.avatar_picture_url" :src="currentUser?.avatar_picture_url" class="w-full h-auto" alt="">
          <span v-else class="flex w-full h-full items-center justify-center font-bold bg-black text-white dark:bg-white dark:text-black rounded-full">
            {{ currentUser?.name[0] }}
          </span>
        </figure>
      </div>
    <!-- </ClientOnly> -->

      <transition
        enter-active-class="transition ease-out duration-300"
        enter-from-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition ease-in duration-200"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">

        <!-- divide-y divide-gray-100  w-44 -->
        <div v-if="isShowProfileSwitch && isLoggedIn" class="absolute right-0 z-30 bg-white absolute rounded-lg shadow-lg dark:bg-stone-900 w-64 p-4">
          <nav class="flex flex-wrap justify-around items-center items-start">
            <NuxtLink class="flex p-2 justify-center items-center cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" v-if="isProducer" :to="`/p/${currentProducer.slug}`">
              <icon-link class="mr-2" />
              Ver perfil público
            </NuxtLink>
            <NuxtLink class="flex p-2 justify-center items-center cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white" to="/conta">
              <icon-user-cog class="mr-2" />
              Conta
            </NuxtLink>
            <a @click.stop.prevent="logout" class="flex p-2 justify-center items-center cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              <icon-logout class="mr-2 w-3 h-auto" />
              Sair
            </a>
          </nav>

          <hr class="dark:border-zinc-700 border-gray-300">

          <small v-if="isProducer || producers.length > 0" class="text-right block my-2 uppercase">
            trocar de perfil
          </small>

          <!-- change-profile-nav -->
          <nav class="">
            <div @click.stop.prevent="changeProducer(producer.slug)" v-for="producer,idx in producers" :key="`producer-${idx}`" class="flex items-center justify-end my-2 py-1 px-2 cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              <div class="block text-right">
                <h3 class="font-medium">{{ producer.name }}</h3>
                <p class="-mb-1 text-sm">produtor (a)</p>
              </div>
              <figure class="w-8 h-8 inline-block ml-2 rounded-full overflow-hidden">
                <img v-if="producer.avatar_picture_url" :src="producer.avatar_picture_url" alt="">
                <span v-else class="flex w-full h-full items-center justify-center font-bold bg-black text-white dark:bg-white dark:text-black rounded-full">
                  {{ producer.name[0] }}
                </span>
              </figure>
            </div>
            <div @click.stop.prevent="changeProducer(null)" class="flex items-center justify-end my-2 py-1 px-2 cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              <div class="block text-right">
                <h3 class="font-medium">{{ currentUser.name.split(' ')[0] }}</h3>
                <p class="-mb-1 text-sm">pessoal</p>
              </div>
              <figure class="w-8 h-8 inline-block ml-2 rounded-full overflow-hidden">
                <img v-if="currentUser.avatar_picture_url" :src="currentUser.avatar_picture_url" alt="">
                <span v-else class="flex w-full h-full items-center justify-center font-bold bg-black text-white dark:bg-white dark:text-black rounded-full">
                  {{ currentUser.name[0] }}
                </span>
              </figure>
            </div>

            <NuxtLink to="/producer_wizard" class="flex p-2 justify-center items-center cursor-pointer py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              Criar perfil de produtor
              <icon-add class="ml-2" />
            </NuxtLink>
          </nav>
        </div>

        <div v-else-if="false && isShowProfileSwitch && !isLoggedIn" class="profile-switch-modal">
          <nav class="current-profile-nav">
            <NuxtLink to="/entrar">
              <icon-link />
              Entrar
            </NuxtLink>
          </nav>
        </div>
      </transition>
    <!-- </ClientOnly> -->
  </div>
</template>

<script setup>
import { useUserStore } from '@/store/user.js'
// import { useMainStore } from '@/store/root.js'

import { storeToRefs } from 'pinia'

import ProducerService from '@/services/producer.service.js'
// import { ClientOnly } from '@/components/ClientOnly.js'
import UserService from '@/services/user.service.js'

const { $posthog, $ahoy } = useNuxtApp()

const router = useRouter()
const route = useRoute()

const isShowProfileSwitch = ref(false)
const producers = ref([])

// const { currentUser, isProducer, currentProducer, loggedIn: isLoggedIn } = useUserStore()
const userStore = useUserStore()
const { currentUser, isProducer, currentProducer, loggedIn: isLoggedIn } = storeToRefs(userStore)


const onProfileSwitchClicked = () => {
  if (!isLoggedIn.value) {
    router.push('/entrar')
    return
  }

  isShowProfileSwitch.value = !isShowProfileSwitch.value
}

const fetchProducers = async () => {
  try {
    if(!isLoggedIn.value) {
      return
    }

    const result = await ProducerService.adminIndex()
    // const result = await ProducerService.index()
    producers.value = result.data
  } catch (_) {
    producers.value = []
  }
}

const changeProducer = async (slug) => {
  await userStore.updateCurrentProducer(slug)
  isShowProfileSwitch.value = false
  if (!slug) {
    router.push('/')
    if($posthog) {
      const posthog = $posthog()
      posthog?.unregister('email')
      posthog?.unregister('userKind')
      posthog?.unregister('producer')
      posthog?.unregister('role')
      posthog?.unregister('eventSlug')
    }
  }
  else {
    if($posthog) {
      const posthog = $posthog()
      posthog?.register({
        email: currentUser.email,
        userKind: currentProducer?.slug ? 'producer' : 'customer',
        producer: currentProducer?.slug ? currentProducer.slug : null
      })
    }

    await navigateTo('https://app.pixta.me/meus_eventos', { redirectCode: 301, external: true })
  }
}

const logout = async () => {
  await UserService.logout()
  await userStore.logout()

  if($posthog) {
    const posthog = $posthog()
    posthog.reset()
  }

  if ($ahoy) {
    const ahoy = $ahoy()
    ahoy.reset()
    ahoy.configure({ headers: { "Authorization": null }});
  }
  // if (route.path != '/') {
  //   await router.push('/')
  // }
}

watch(isShowProfileSwitch, (val) => {
  if (val) {
    fetchProducers()
  }
})

watch(() => route.path, () => {
  isShowProfileSwitch.value = false
})
</script>

<style lang="scss" scoped>

</style>
